import { Points, Team } from './interfaces';

export default function sum(array: number[]) {
  const sum = array.reduce((acc, cur) => acc + cur, 0);

  return Number(sum.toFixed(2));
}

const meetingPoints: Points[] = [
    { name: "Chuck Kersch", points:  [1,1,1,1,1,1,1,0,0,1,0,0] },
    { name: "Don Rogers", points:    [1,1,1,1,1,1,1,0,0,1,0,0] },
    { name: "Henry Vera Sr", points: [1,1,1,1,1,1,1,0,0,1,0,0] },
    { name: "Henry Vera Jr", points: [1,1,1,1,1,1,1,0,0,1,0,0] },
    { name: "Jacob Saenz", points:   [1,1,1,1,0,0,1,1,1,1,0,0] },
    { name: "John Trevino", points:  [1,1,1,1,1,1,0,1,1,1,0,0] },
    { name: "Karl Rogers", points:   [1,1,1,1,0,0,0,0,0,0,0,0] },
    { name: "Shawn Warren", points:  [1,0,1,1,1,1,0,1,1,1,0,0] },
]

const teams: Team[] = [
    {
        "members": ["Chuck Kersch", "John Trevino"],
        "meeting_points": 0,
        "tournament_points": sum([4,5.04,3.45,5.83,7.14,3.77,1,1,2.75,2.35,1,0]),
    },
    {
        "members": ["Don Rogers", "James Garnand"],
        "meeting_points": 0,
        "tournament_points": sum([4.14,1,3.79,9.65,6.42,1,1,1,9.94,7.71,6.21,0]), 
    },
    {
        "members": ["Henry Vera Sr", "Henry Vera Jr"],
        "meeting_points": 0,
        "tournament_points": sum([5.35,9.31,9.5,5.37,1.93,2.11,1,1,3.66,10.35,5.96,0]), 
    },
    {
        "members": ["Jacob Saenz", "Shawn Warren"],
        "meeting_points": 0,
        "tournament_points": sum([6.55,2.46,6.21,4.99,1,0,0,1,2.30,0,7.65,0]), 
    },
    {
        "members": ["Karl Rogers"],
        "meeting_points": 0,
        "tournament_points": sum([2.24,0,0,0,0,0,0,0,0,0,0,0]), 
    },
]

const addMeetingPoints = (teams: Team[], meetingPoints: Points[]) => {
    // Iterate over the meeting points
    for (const meetingPoint of meetingPoints) {
      // Find the team that matches the meeting point's name
      const team = teams.find((t) => t.members.includes(meetingPoint.name));
  
      // If the team is found, add the meeting point's points to the team's meeting points
      if (team) {
        team.meeting_points += sum(meetingPoint.points);
      }
    }
  };
  
addMeetingPoints(teams, meetingPoints);
  
export const TeamsData: Team[] = teams
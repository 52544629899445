import { Payout, Report } from './interfaces';

/* Add new Data here and then add new function in
   src/router/variables.tsx 
*/
export const ReportsData: Report[] = [
  { year: "2020",
    data: [
      { month: "January", url: "https://storage.googleapis.com/wzukusers/user-18727687/documents/b77f9853011d4342a0a8c5f565e7922b/Treasurers%20Report%2001-02-20.pdf"},
      { month: "February", url: "https://docs.google.com/document/d/1B_HKOxu24mHmpVg71Nd57LjPGST_nFgzgqvUMSJ4yeI/edit?usp=sharing"},
      { month: "March", url: "https://docs.google.com/document/d/1QjUBc9zi062j2wYU2X9kQnzXmveeOJzMggvpvoQwzQ8/edit?usp=sharing"},
      { month: "April", url: "https://docs.google.com/document/d/1Nv53QfJgFC1LGzvYgsxjEUBjNQL7Cv4RxeLKXdRsIHY/edit?usp=sharing"},
      { month: "May", url: "https://docs.google.com/document/d/1NO1l33zgPjAgrGSPJSm0NafYKZ1k0yCOkqR4ov0n9ak/edit?usp=sharing"},
      { month: "June", url: "https://docs.google.com/document/d/1tyRpXByE_8q-DuDULmIw-7hDE45yM3ogD_1BoAAPFhw/edit?usp=sharing"},
      { month: "July", url: "https://docs.google.com/document/d/1DQMBGfpSqaU4dVwp0oyVuJ_jic75y5rpt67G2lIWLdU/edit?usp=sharing"},
      { month: "August", url: ""},
      { month: "September", url: ""},
      { month: "October", url: "https://docs.google.com/document/d/1HjipOKJWQankwXOhjxJ1XYOOdWuqq2LniuCMIvshLZc/edit?usp=sharing"},
      { month: "November", url: "https://docs.google.com/document/d/1zrgwmB9WM0DYDLIdK9XEuyaUX6qKbqCujfpdZaQakrk/edit?usp=sharing"},
      { month: "December", url: ""},
    ]
  },
  { year: "2021",
    data: [
      { month: "January", url: "https://docs.google.com/document/d/1cDH2C3HqbLYxarcJh5xMDdOVvTkZbc9ngBU1TUU-nNA/edit?usp=sharing"},
      { month: "February", url: "https://docs.google.com/document/d/1rEcJxo-GIWjz-pVzFHQfHkwTcr9x5PQ40FxOCoC7ixM/edit?usp=sharing"},
      { month: "March", url: "https://docs.google.com/document/d/1UCqeq7cWYIN-6LvUdBh8StOt6-WmDWLajH1kaIpyfig/edit?usp=sharing"},
      { month: "April", url: "https://docs.google.com/document/d/17DT3ffIKGaUu7au91K9mIvnn8ZkzO1n7memLRxrq8cI/edit?usp=sharing"},
      { month: "May", url: "https://docs.google.com/document/d/17LyvYKzKqUnyDgzmFKCjy6nEsBk0Z_cQ0eg4iITLvQY/edit?usp=sharing"},
      { month: "June", url: "https://docs.google.com/document/d/1Y41JJBz7lESjQRpE8_jHLxlh_L52RLq7i8hvJajzgAo/edit?usp=sharing"},
      { month: "July", url: "https://docs.google.com/document/d/1M8JjfIwvDGd2kEsxaznXUSJFG-DyGB1aJG7pMRi2GMs/edit?usp=sharing"},
      { month: "August", url: "https://docs.google.com/document/d/1Hd_zBVbyA3QveMclcfrVGj822FW0XVwDDEFH9Ci3KEM/edit?usp=sharing"},
      { month: "September", url: "https://docs.google.com/document/d/1xP4B39xH75esad4gD4XwtXGqkCsKyoJYpRJSadhhjL0/edit?usp=sharing"},
      { month: "October", url: "https://docs.google.com/document/d/1u5VPgJYIwwG-bRvpc7pwsGch5GMN8mwe5q47Bj-NXxs/edit?usp=sharing"},
      { month: "November", url: "https://docs.google.com/document/d/1y-47jqpIvJha-YdNHJjV5Z1vIp-2Ywxf8G7-VkZdrFQ/edit?usp=sharing"},
      { month: "December", url: "https://docs.google.com/document/d/1J-rIc9AyBitw1rtKBCn9AOPJtj-pUf8Wzx37LgnLsOA/edit?usp=sharing"},    
    ]
  },
  { year: "2022",
    data: [
      { month: "January", url: "https://docs.google.com/document/d/15ijKYGZKyNRI_vk_mB3pr4pn74x-iuIZFkOsxAWhbHk/edit?usp=sharing"},
      { month: "February", url: "https://docs.google.com/document/d/1wusq3uZE4xEpLFBJWoBV3EjZX9m0cfkoEaZlf1_uNvA/edit?usp=sharing"},
      { month: "March", url: "https://docs.google.com/document/d/1cX2RvZlEit52EKEkOvTnnViQWT_lTsn_QEZam-QS0Es/edit?usp=sharing"},
      { month: "April", url: "https://docs.google.com/document/d/19dPAsW11-PQTd5MSa4yntVxRi6XPCgjJ2yCWkibEAVE/edit?usp=sharing"},
      { month: "May", url: "https://docs.google.com/document/d/1StE7ZvzWK6HLsLZ23eOa8yxIUS-eV_DHpJE5QpM3FvU/edit?usp=sharing"},
      { month: "June", url: "https://docs.google.com/document/d/1sKb3DPSuZYTwbLl2TIWNWLswNppDulWS6Z5GmLaIfP4/edit?usp=sharing"},
      { month: "July", url: "https://docs.google.com/document/d/14xcrvtW0LZ4MC8lcPuWFgmOr4leYHg4g2jn3n2Xsqxo/edit?usp=sharing"},
      { month: "August", url: "https://docs.google.com/document/d/1z1SiYCQovlH5LsGPPOnC-O3eB2NOx2VIhxKhjAogK54/edit?usp=sharing"},
      { month: "September", url: "https://docs.google.com/document/d/1hRG9djRakFG9S6Qnk_Yzt8CCgtIOFVaxhsBhZNQ-yQQ/edit?usp=sharing"},
      { month: "October", url: "https://docs.google.com/document/d/1Ky1PxHoeeflvS8fp4ViuLaRNvzGOjuQVuYkOjf1Db2A/edit?usp=sharing"},
      { month: "November", url: "https://docs.google.com/document/d/1fy7DHB4lrVM57FkbPLHmgqLa01VrbPkLV7BLcu1k1ng/edit?usp=sharing"},
      { month: "December", url: ""},    
    ]
  },
  { year: "2023",
    data: [
      { month: "January", url: "https://docs.google.com/document/d/1yppfrg7WPTEfmbAFreTYqU2W-EgP0GzuTQIx1ZlkWZ0/edit?usp=sharing"},
      { month: "February", url: "https://docs.google.com/document/d/1OviAuBV71r0az6NsDUv3YrkjTfaK7S84Ogp9t29BAR4/edit?usp=sharing"},
      { month: "March", url: "https://docs.google.com/document/d/1tFU3nOt_5WHQqDUG5gSRCG9tr9v_B85qPBmaqPFgfOM/edit?usp=sharing"},
      { month: "April", url: "https://docs.google.com/document/d/1Dvj8QR3BZERe_QKMW42RsqM9kNebylvc7FndNlEzh-0/edit?usp=sharing"},
      { month: "May", url: "https://docs.google.com/document/d/1ovyRxKK0QX3ZqHhVx_khGuELwiOmJR9BlrUQszBoYTc/edit?usp=sharing"},
      { month: "June", url: "https://docs.google.com/document/d/17ug_K5ox0xObKmOAzPFBlQFGzJ48QsB8O5daFUCQ_Jc/edit?usp=sharing"},
      { month: "July", url: "https://docs.google.com/document/d/1u5o0lreQEANeP7pQ_zPvs07myqONbbJU4E121qiiD9c/edit?usp=sharing"},
      { month: "August", url: "https://docs.google.com/document/d/1LJCoNFLZrmr7w3VU0y5dEga5rpZT-rNk927S7-QDL5o/edit?usp=sharing"},
      { month: "September", url: "https://docs.google.com/document/d/1hFvBrtHk2R7svw4234-Br7_S1X3lErU6Le-EPOjy3LE/edit?usp=sharing"},
      { month: "October", url: "https://docs.google.com/document/d/1rhMgKgqrO0UjIrqPJHwazgTp9x774rC_3detQe5QblA/edit?usp=sharing"},
      { month: "November", url: "https://docs.google.com/document/d/1v7PatPmJ8JYipLCA8UpLQIL0x1h16CxV15v3pnf65MI/edit?usp=sharing"},
      { month: "December", url: "https://docs.google.com/document/d/1Aqh4TfIviA4IghhuCl2frCtFNIzOVlkNSvdjtzB6hQE/edit?usp=sharing"},    
    ]
  },
  { year: "2024",
    data: [
      { month: "January", url: "https://docs.google.com/document/d/17ch6zq-gjkgzAJuYCsY3WKzKgf15hqVW-tXyZzI7XjA/edit?usp=sharing"},
      { month: "February", url: "https://docs.google.com/document/d/1zCikhPWOWOsac2E4UskHA6S7r7fMi-qAZfMf2qDyOV8/edit?usp=sharing"},
      { month: "March", url: "https://docs.google.com/document/d/1iZLeBbRsd4-yxCzqySE1M9NUIFrESwlbYpDxmNkMXtw/edit?usp=sharing"},
      { month: "April", url: "https://docs.google.com/document/d/1b0qtz-scoS8GK9RI5K0D-Ojk3mX-kJ_PAyACavwSimk/edit?usp=sharing"},
      { month: "May", url: "https://docs.google.com/document/d/1yA0_XxlXWk7uFl0WcVLsZrko43CjgWDz81iZH6ibxao/edit?usp=sharing"},
      { month: "June", url: "https://docs.google.com/document/d/1Faogiv0j06N0vOhll6c-9TH1n-V69TUeY_xJnqNgGo4/edit?usp=sharing"},
      { month: "July", url: "https://docs.google.com/document/d/1dFsDbPPe2o9R_KqyIJ9WQ-ECvYiqFskh7qcRU3b_NhQ/edit?usp=sharing"},
      { month: "August", url: "https://docs.google.com/document/d/1bH3Mh6MFU7txNHMDJp05RMWVEdUhtH0ggz1GYJ9TGFU/edit?usp=sharing"},
      { month: "September", url: "https://docs.google.com/document/d/1ezDFbRX2zOQPr39DUoQ2xNiJvujm8_h_5UDu_nH0bUc/edit?usp=sharing"},
      { month: "October", url: "https://docs.google.com/document/d/1zOMe-jlLQZEW4YCBoQ_xt7obalpWd7u11Fm32Ry9JYo/edit?usp=sharing"},
      { month: "November", url: "https://docs.google.com/document/d/1YG4HJlNgVXJCL9l_9iB-mLrokzVN3NBQCS9Z3UkVJhI/edit?usp=sharing"},
      { month: "December", url: ""},
    ]
  }
]

function percentage( ammount: number, percent: number) {
  return (percent / 100) * ammount
}

var balance = 388.00
var balance80 = percentage(balance, 80)
var aotyBalance = percentage(balance80, 80)
var aoty1 = String(percentage(aotyBalance, 50))
var aoty2 = String(percentage(aotyBalance, 30))
var aoty3 = String(percentage(aotyBalance, 20))
var party = String(percentage(balance, 20))
var bbBalance = String(percentage(balance80, 20))
var roundTo = 0

export const PayoutsData: Payout[] = [
  { type: "Balance", ammount: "$" + parseFloat(String(balance)).toFixed(roundTo) },
  { type: "Party", ammount: "$" + parseFloat(party).toFixed(roundTo) },
  { type: "1st", ammount: "$" + parseFloat(aoty1).toFixed(roundTo) },
  { type: "2nd", ammount: "$" + parseFloat(aoty2).toFixed(roundTo) },
  { type: "3rd", ammount: "$" + parseFloat(aoty3).toFixed(roundTo) },
  { type: "BigBass", ammount: "$" + parseFloat(bbBalance).toFixed(roundTo) }
]

export const EventVar = {
    meeting: {
        date: "November 7th",
        time: "7 PM",
        location: "Bill Miller's"
    },
    tournament:
    {
      date: "November 16th",
      location: "Lake LBJ - Wirts Ramp"
    }
  }  
import React from 'react';
import { Card, Container, Col, Row } from 'react-bootstrap';

import '../css/common.css';

import { AOTYData, AOTYBBData, WinnersData, WinnerBBData } from './variables';
import { AOTYProps, WinnersProps, Winner } from './interfaces';

const AOTY: React.FC<AOTYProps> = ({ anglers }) => {
    return (
      <p>2023 End of the Year Winners
        {anglers.map(angler => (
            <span style={{ display: 'block' }} key={angler.place}>{ angler.place} - { angler.name }</span>
            )
        )}
      </p>
    );
};

const checkWinner = (winner: Winner) => {
    if (winner.name.length > 0) {
        return true
    } else {
        return false
    }
};

const TournamentWinners: React.FC<WinnersProps> = ({ winners }) => {
    return (
      <p>Tournament Winners
        {/* eslint-disable-next-line array-callback-return */}
        {winners.map(winner => {
            if (checkWinner(winner)) {
                return (
                    <span style={{ display: 'block' }} key={winner.place}>{ winner.place} - { winner.name }</span>
                );
            }
        })}
      </p>
    )
};

const checkWinnerBB = (bbweight: number) => {
    if (bbweight === 0) {
        return false
    } else {
        return true
    }
};

export function Winners() {
    if (checkWinnerBB(WinnerBBData.weight)) {
        return (
            <div>
                <h2>Winner&lsquo;s Circle</h2>
                <AOTY anglers={ AOTYData }></AOTY>
                <Container style={{ display: 'flex', justifyContent:'center' }}>
                    <Row>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={ AOTYBBData.image_url } alt="2023 Big Bass" />
                        <Card.Body>
                            <Card.Title className='mb-2 text-muted'>Big Bass 2023</Card.Title>
                            <Card.Text className='mb-2 text-muted'>{ AOTYBBData.weight } lb caught at { AOTYBBData.location} by { AOTYBBData.angler } on { AOTYBBData.date }.</Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    </Row>
                </Container>
                <TournamentWinners winners={ WinnersData }></TournamentWinners>
                <Container style={{ display: 'flex', justifyContent:'center' }}>
                    <Row>
                    {/* <Col>
                        <Card style={{ width: '18rem' }}>
                        {<Card.Img variant="top" src={ WinnerBBData.image_url } alt={ WinnerBBData.location } />}
                        <Card.Body>
                            <Card.Title className='mb-2 text-muted'>Big Bass { WinnerBBData.date } at { WinnerBBData.location }</Card.Title>
                            <Card.Text className='mb-2 text-muted'>{ WinnerBBData.weight } lb caught by { WinnerBBData.angler }.</Card.Text>
                        </Card.Body>
                        </Card>
                    </Col> */}
                    </Row>
                </Container>
            </div>
        );
    } else {
        return (
            <div>
                <h2>Winner&lsquo;s Circle</h2>
                <AOTY anglers={ AOTYData }></AOTY>
                <Container style={{ display: 'flex', justifyContent:'center' }}>
                    <Row>
                    <Col>
                        <Card style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={ AOTYBBData.image_url } alt="2022 Big Bass" />
                        <Card.Body>
                            <Card.Title className='mb-2 text-muted'>Big Bass 2022</Card.Title>
                            <Card.Text className='mb-2 text-muted'>{ AOTYBBData.weight } lb caught at { AOTYBBData.location} by { AOTYBBData.angler } on { AOTYBBData.date }.</Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>
                    </Row>
                </Container>
                <TournamentWinners winners={ WinnersData }></TournamentWinners>
            </div>
        )
    }
}

export default Winners;